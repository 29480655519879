import { Link } from "gatsby"
import React from "react"
import "./HeroContent.styl"

export default function HeroContent() {
  return (
    <div className="hero-content">
      <h3
        className="hero-content__main-title "
        data-sal="slide-right"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="ease"
      >
        Servicios y desarollos KLS
      </h3>
      <div
        className="hero-content__main-text"
        data-sal="slide-right"
        data-sal-delay="200"
        data-sal-duration="900"
        data-sal-easing="ease"
      >
        Tú mano derecha en trabajos de construcción.
      </div>
      <Link to="#servicios">
        <div
          className="hero-content__call-to-action"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-duration="1300"
          data-sal-easing="ease"
        >
          Ver Servicios
        </div>
      </Link>
    </div>
  )
}
